import { render, staticRenderFns } from "./logo-garden.vue?vue&type=template&id=5f8c5d14&scoped=true&lang=pug&"
import script from "./logo-garden.vue?vue&type=script&lang=coffee&"
export * from "./logo-garden.vue?vue&type=script&lang=coffee&"
import style0 from "./logo-garden.vue?vue&type=style&index=0&id=5f8c5d14&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f8c5d14",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Btn: require('/opt/build/repo/nuxt-app/components/globals/btn/btn.vue').default,BasicLogo: require('/opt/build/repo/nuxt-app/components/globals/basic-logo.vue').default,SsrCarousel: require('/opt/build/repo/node_modules/vue-ssr-carousel/index.js').default})
