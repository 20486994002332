
























import breakpoints from 'library/assets/vars/breakpoints'

export default

	props: block: Object

