















import { makeImgixUrl } from '@bkwld/cloak/services/helpers'

export default

	props: logo: Object

	computed:
		logoImage: -> makeImgixUrl @logo?.logo?[0]?.path
		href: -> @logo?.href

